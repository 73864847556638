import React from "react";
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarFixedAdjust,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import "./TopBar.scss";
import { Link } from "react-router-dom";

export default function TopBar() {
  return (
    <>
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection className="top-bar">
            <nav className="main-nav">
              <Button unelevated label="Videos" tag={Link} to="/videos" />
              <Button unelevated label="Articles" tag={Link} to="/articles" />
              <Button unelevated label="Speaking" tag={Link} to="/speaking" />
            </nav>
            <nav className="right-nav">
              <Button
                unelevated
                label="GitHub"
                tag="a"
                href="http://github.com/drewwiens"
              />
            </nav>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </>
  );
}
