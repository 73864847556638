import React from "react";
import "./Badge.scss";

export default function Badge() {
  const odLink = <a href="https://www.oasisdigital.com/">Oasis Digital</a>;
  return (
    <div class="badge">
      <img src="images/me.PNG" />
      <span>
        Hello! My name is Drew. I'm a software engineer, consultant at {odLink},
        and recreational skiier.
      </span>
    </div>
  );
}
